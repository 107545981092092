<template>
  <div>
    <div class="row row-no-gutters">
      <div
        class="col-12"
        style="margin-top: 10px"
        v-for="(period, idx) in periodDates">
        <div>
          <p
            style="
              background-color: #2eafb4;
              text-transform: uppercase;
              font-weight: bold;
              margin-bottom: 0;
            "
          >
            {{ getDay3(period[0].metadata.date + 'T' + '00:00') }}
          </p>
          <p
            style="
              color: silver !important;
              font-size: 1.4rem;
              background-color: black;
              font-weight: bold;
            "
          >
            {{ getDay2(period[0].metadata.date + 'T' + '00:00') }}
          </p>
        </div>
        <div v-for="event in period" :key="event.slug">
          <MiniArtistCard v-if="event.metadata.main_image"
                          :image="event.metadata.main_image.imgix_url"
                          :event="event"
            @click="collapsed = !collapsed"
          ></MiniArtistCard>
          <MiniArtistCard v-if="!event.metadata.main_image"
                          :event="event"
                          @click="collapsed = !collapsed"
          ></MiniArtistCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniArtistCard from '@/components/Cards/MiniArtistCard';
import moment from 'moment';

export default {
  name: 'DjScheduleCollection',
  props: ['desktop','nye'],
  methods: {
    getDay: function getYear(date) {
      return moment(date, true).format('ddd');
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD');
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM');
    },
  },
  data: () => {
    return {
      showIdx: 0,
      showAction: '',
    };
  },
  computed: {
    djSchedule: {
      get() {
        return this.$store.state.djSchedule;
      },
    },
    periodDates: {
      get() {
        let _periodDates = {};
        this.djSchedule.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            _periodDates[y.metadata.date].push(y);
          } else {
            _periodDates[y.metadata.date] = [];
            _periodDates[y.metadata.date].push(y);
          }
        });
        return _periodDates;
      },
    },
  },
  created: function () {
      this.$store.dispatch('getNyeDjSchedule');
  },

  components: {
    MiniArtistCard,
  },
};
</script>
