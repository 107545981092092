<template>
  <div class="content">
    <div
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <div class="text-center col-lg-8 col-sm-12">
        <div class="header text-center" style="margin-bottom: 10px">
          <h1
            class="tulum-party-title"

            style="font-size: 2.4rem; margin-top: 20px;"
          >
            TULUM MX ● NEW YEAR
          </h1>

          <h5 style="margin-top: -20px">
            <span style="color: #2eafb4">&gt;&nbsp;</span>
            <strong>DANCE TO CELEBRATE</strong>
            <span style="color: #2eafb4">&nbsp;&lt;</span>
          </h5>
          <br/>
          <img
            v-lazy="tulumpartyLogo + '?h=12'"
            height="12px"
            alt="tulum party logo"
          />
          <h5 class="tulum-party-title" style="font-size: 0.7rem; color: #2eafb4">
            NYE - HIGH SEASON 2024 - 2025
          </h5>

          <card class="card text-center">
            <template slot="header">
              <a href="#event-line">
                <img
                  height="150px"
                  width="150px"
                  v-lazy="mainImage + '?w=150&h=150&fit=clamp&auto=format'"
                  alt="tulum nye 2024"
                />
              </a>

              <div
                style="
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                max-width: 500px;
              "
              >
                <div style="" class="floating">
                  <pre style="">^</pre>
                  <span
                    class="tulum-party-title"
                    style="
                    font-size: 0.8rem;
                    margin-top: -20px;
                    color: #2eafb4;
                    letter-spacing: 0.9rem;
                  "
                  >NYE 2024 - 2025 SEASON</span
                  >
                  <br/>
                  <strong style="color: #2eafb4">Tulum, Mexico</strong>
                </div>
              </div>
              <br/>
              <div
                class="card"
                style="
                  z-index: 1;
                  max-width: 500px;
                  margin-left: auto;
                  margin-right: auto;
                  left: 0;
                  right: 0;
                "
              >

                <strong style="margin-top:5px; font-size: 1rem">
                  Dance to Celebrate</strong
                >
                <div style="margin: 30px" class="text-center centered">

                  <h5
                    class="tulum-party-title"
                    style="font-size: 1.2rem; color: #2eafb4"
                  >
                    NYE  SEASON
                    <br>2024/2025
                  </h5>
                  <MiniCarrousel folder="festival-nye" num-photos="4"
                                 title="The largest musical celebration for New Year happens at Tulum Mexico"></MiniCarrousel>
                </div>

                <div class="card" style="display: block">

                  The Best <a href="/brands">event producers</a> , <a href="/tulum-dj-schedule">electronic music
                  artists</a>,
                  <a href="/tulum-clubs/clubs">clubs</a>
                  & <a href="https://instagram.com/tulum.party" target="_blank"
                >promoters </a
                >meet in a single place to produce world class shows for a
                  world-class audience.
                </div>



              </div>


            </template>
          </card>
        </div>

        <card
          class="card text-center"
          style="border: 1px solid black; background-color: rgb(0 0 0 / 32%)"
        >
          <template slot="header">
            <br>
            <img
              v-lazy="astroImg + '?h=30&w=30'"
              height="30px"
              width="30px"
              alt="tulum party support"
              style="
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              margin-top: 20px;
            "
            />
            <br/>
            <br/>


            <p>

              Got a question, big or small? <br>We’re here to answer your call.
            </p>
            <br/>
            <a
              href="https://wa.me/524421867547?text=NYE_INFO"
              v-on:click="onQuickReserveClick()"
              class="btn font-weight-bold"
              style="background-color: black !important; border: 1px solid silver"
            >
              📲 INFO & RSVP
            </a>
            <br/>
            <br/>

            <a
              href="mailto:bookings@tulum.party"
              v-on:click="onQuickReserveClick()"
            >bookings@tulum.party</a
            >
            <br/>

            <small> We operate from 8AM - 11PM / Answer within 15 mins</small>
            <br/>
            <small style="color: #fff">NO EXTRA FEE</small>
            <br/>
            <br/>


          </template>
        </card>
        <div class="row">
          <div
            class="card text-center"
            style="margin: 40px; background-color: #171818"
          >
            <h3
              class="title tulum-party-title"
              style="margin-bottom: 0; margin-top: -40px; font-size: 32px"
              id="event-line"
            >
              Event line
            </h3>
            <h4
              class="tulum-party-subtitle"
              style="color: black !important; background-color: #2eafb4"
            >
              NYE - HIGH SEASON 2024/2025
            </h4>

            <!--          <img v-lazy="thumbnailEye+'?w=200&h=100&fit=clamp'" width="200px" height="100px"-->
            <!--               @click="changeView"-->
            <!--               alt="search parties"-->
            <!--               class="card-animation-on-hover"-->
            <!--               style="border-radius:90px;z-index: 1;margin-left: auto;margin-right: auto;left: 0;right: 0;margin-top: -40px;max-width: 350px;background-color: black; padding: 15px;">-->

            <div class="form-group form-control">
              <div
                class="input-group input-group-lg"
                style="
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                margin-top: 2px;
              "
              >
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="sizing-addon1"
                  name="search_nye"
                  autocomplete="false"
                  v-model.lazy="searchTerm"
                  v-on:change="filterParties"
                  placeholder="Search ..."
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-10 offset-lg-1">

            <div class="btn-group-md" style="background-color: #171818">

              <a
                @click="changeView('detailed')"
                class="btn btn-info"
                :class="this.view === 'detailed' && 'active'"
              >
                <i class="tim tim-icons icon-single-copy-04"></i>
                <br>
                <small>DETAIL</small>
              </a>
              <a
                @click="changeView('compact')"
                class="btn btn-info"
                :class="this.view === 'compact' && 'active'"
              >
                <i class="tim tim-icons icon-bullet-list-67"></i>
                <br>
                <small>LIST</small>
              </a>

              <a
                @click="changeView('artist')"
                class="btn btn-info d-xl-none"
                :class="this.view === 'artist' && 'active'"
              >
                <i class="tim tim-icons icon-badge"></i>
                <br>
                <small>DJ SCHEDULE</small>
              </a>

              <br/>

              <br/>
              <p>
                <del>LINKTREE</del>&nbsp;| CENOTREE
              </p>
              <p><strong>MAX DEPTH </strong></p>
              <pre>UNKNOWN</pre>


            </div>


            <!--          style="background: linear-gradient(45deg, black, #2eafb4, #3cc4a6)"-->

            <div
              class="text-center"
              style="background: linear-gradient(45deg, black, #2eafb4)"
            >
              <h4 class="text-left section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  alt="An Eye"
                />
                &nbsp;EVENT LINE
              </h4>
              <h4 v-if="searchTerm && festivalParties.length === 0">
                No match for your query: {{ searchTerm }}
              </h4>
              <p
                v-if="searchTerm"
                style="font-size: 14px;margin-top: 10px;cursor: pointer"
                class="badge badge-dark"
                @click="
                searchTerm = null;
                filterParties();
              "
              >
                <span>{{ festivalParties.length }} events found</span> <br><br>

                <span style="color: #4BA663;">x</span> Filter:
                {{ searchTerm }}

              </p>
              <h4 v-if="view === 'artist'">DJ Schedule</h4>

              <time-line v-if="view === 'detailed'">
                <time-line-item
                  :full="true"
                  v-for="(event, idx) in festivalParties"
                  :key="event._id"
                  :inverted="idx % 2 === 0"
                  :badge-type="badgeType(idx)"
                >
                  <div slot="content">
                  <pre style="background-color: #0e0e0e; opacity: 0.3"
                  >{{ idx + 1 }} mts</pre
                  >

                    <EventCard
                      :with-lineup="false"
                      v-bind:event="event"
                      :key="event._id"
                      :idx="idx"
                    ></EventCard>

                  </div>
                </time-line-item>
              </time-line>
              <div v-if="view === 'compact'">
                <mini-event-card
                  :event="event"
                  :key="event.slug"
                  v-for="(event) in festivalParties"
                ></mini-event-card>
              </div>
              <div v-if="view === 'artist'">
                <DjScheduleCollection :desktop="true" :nye="true"></DjScheduleCollection>
              </div>
              <br/>
              <br/>
            </div>

            <section style="margin-top: 35px" class="card row"
                     :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
            >
              <br>



              <h4 class="text-left section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  alt="AN Eye"
                />
            &nbsp;MEMORIES
              </h4>
              <hr
                style="height: 1px; margin-top: -10px"
                :style="{ backgroundColor: this.secondaryColor }"
              />



              <HighLightCard></HighLightCard>
              <div class="card" style="padding: 14px">
                <p style="padding: 15px; " class="text-center">
                  Year-round, Tulum is the destination for thousands of people who come to see natural wonders. However, there's another kind of explorer in Tulum: The seeker of thrills, of emotion & raves. Tulum is the home base for those who come looking for an experience, synchronizing man-made wonders and natural atmospheres.
                </p>
                <p>The winter season is where you can find most of the big festivals, so it's no surprise that every year this season becomes the party season.</p>
              </div>
            </section>

          </div>


        </div>

      </div>


      <div class="card col-4 col-lg-4 d-none d-lg-inline">
        <div class="row">
          <section style="margin-top: 35px" class="card row"
                   :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
          >
            <h4 class="text-left section-header">
              <img
                v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                alt="An Eye"
              />
              &nbsp;DJ SCHEDULE
            </h4>
            <hr
              style="height: 1px; margin-top: -10px"
              :style="{ backgroundColor: this.secondaryColor }">

          <DjScheduleCollection nye="true" desktop="true"></DjScheduleCollection>
          </section>
        </div>

        <div class="row">
          <h4 class="section-header">Production Brands</h4>
          <BrandCollection></BrandCollection>
        </div>
      </div>
      <div class="col-sm-12 col-lg-10 offset-lg-1 text-center">
        <a
          class="btn btn-primary btn-link"
          style="
          font-size: 12px;
          color: whitesmoke !important;
          text-transform: lowercase;
        "
          href="https://www.instagram.com/tulum.party"
        >
          <img
            height="17px"
            width="17px"
            v-lazy="insta"
            alt="follow us at instagram"
          />
          tulum.party
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {TimeLine, TimeLineItem} from 'src/components';
import EventCard from '../../components/EventCard/EventCard';
import moment from 'moment';
// import MobileDjCollection from "@/components/Cards/MobileDjCollection";
import DjScheduleCollection from '@/components/Cards/DjScheduleCollection';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import BrandCollection from "@/components/Cards/BrandCollection";
import HighLightCard from "@/components/Cards/HighLightCard";
import MiniCarrousel from "@/components/MiniCarrousel";

export default {
  name: 'TulumNye2024',
  created: function () {
    this.$store.dispatch('getFestival', {slug: this.slug}).then(() => {
      // console.log(this.$store.state.festival.metadata.parties)
    });
  },

  props: ['slug'],
  metaInfo() {
    return {
      title: 'Tulum NYE 2024/2025 · New Year Festival · Event Line',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.tulum.party/tulum-new-year-festival-2024',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Tulum NYE 2024/2025. Information, Tickets & RSVP.`,
        },
        {
          name: 'keywords',
          content: `Tulum Festival 2024 2025 Rave DJ Parties`,
        },
        {
          property: 'og:title',
          content: `Tulum NYE 2024/2025. Tickets & Information`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-new-year-festival-2024`,
        },
        {
          property: 'og:description',
          content: `Tulum NYE 2024/2025. Events @ Tulum Mexico`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/de804f80-8ec0-11ee-b953-fbe0f06ed1b1-image.png',
        },
      ],
    };
  },
  data: () => {
    return {
      view: 'detailed',
      // festivalParties: [],
      highlightStartIdx: 0,
      searchTerm: null,
      secondaryColor: '#009688',
      palmeras:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      tulumpartyLogo:
        'https://imgix.cosmicjs.com/afd1db60-3abd-11ee-b850-2d3046e40f87-poster.png',
      insta:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&auto=format',
      buzosImage:
        'https://imgix.cosmicjs.com/15d07430-9a09-11ee-bdf1-7fea048bb7c9-image.png',
      mainImage:
        'https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png',
      thumbnailEye:
        ' https://imgix.cosmicjs.com/233cbf00-8ec1-11ee-b953-fbe0f06ed1b1-image.png',
      marcianitos:
        'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
    };
  },
  components: {
    MiniCarrousel,
    HighLightCard,
    MiniEventCard,
    DjScheduleCollection,
    TimeLine,
    TimeLineItem,
    EventCard,
    BrandCollection,
  },
  // watch:{
  //   search(a) {
  //     console.log(a);
  //   }
  // },
  methods: {
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Event Line - NYE 2024',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55',
      });
    },
    filterParties: function filterParties() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 4) {
          this.$rollbar.info('NYE SEARCH', {keyword: this.searchTerm});
        }
        this.notifyChange('Showing Results For: ' + this.searchTerm);
        this.changeView('compact');
      }
      return [...this.$store.state.festival.metadata.parties]
        .sort((a, b) => {
          let time_a = a.metadata.start_time || '00:00';
          let time_b = b.metadata.start_time || '00:00';
          return moment.utc(a.metadata.date + 'T' + time_a) >
          moment.utc(b.metadata.date + 'T' + time_b)
            ? 1
            : -1;
        })
        .filter((item) => {
          return this.searchTerm
            ? item.slug.match(this.searchTerm.toLowerCase()) ||
            item.title.toLowerCase().match(this.searchTerm.toLowerCase()) ||
            item.metadata.lineup
              .toLowerCase()
              .match(this.searchTerm.toLowerCase())
            : true;
        });
    },
    goToParty(party) {
      this.$router.push({path: '/event/' + party.target.value});
      this.$rollbar.info('NYE - QUICK PARTY: ' + party.target.value);
    },
    changeView(state) {
      this.view = state;
      // this.notifyChange('View:' + state);
    },
    formatDate(date) {
      return moment(date).format(' DD MMM ');
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'NYE Info Request',
        'Main',
        30
      );
      this.$rollbar.info('NYE Info Request');
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    festivalParties: {
      get() {
        // if (this.searchTerm) {
        //   if (this.searchTerm.length > 4) {
        //     this.$rollbar.info('NYE SEARCH', { keyword: this.searchTerm });
        //   }
        //   this.notifyChange('Showing Results For: ' + this.searchTerm);
        //   this.changeView('compact');
        // }
        return [...this.$store.state.festival.metadata.parties]
          .sort((a, b) => {
            let time_a = a.metadata.start_time || '00:00';
            let time_b = b.metadata.start_time || '00:00';
            return moment.utc(a.metadata.date + 'T' + time_a) >
            moment.utc(b.metadata.date + 'T' + time_b)
              ? 1
              : -1;
          })
          .filter((item) => {
            let partyDateEndOfDay = moment.utc(item.metadata.date).endOf('day');
            let now = moment().startOf('day');
            return partyDateEndOfDay >= now;
          })
          .filter((item) => {
            return this.searchTerm
              ? item.slug.match(this.searchTerm.toLowerCase()) ||
              item.title
                .toLowerCase()
                .match(this.searchTerm.toLowerCase()) ||
              item.metadata.lineup
                .toLowerCase()
                .match(this.searchTerm.toLowerCase())
              : true;
          });
      },
    },
  },
};
</script>
